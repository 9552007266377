import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55a00314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "store-deposit" }
const _hoisted_2 = { class: "store-head" }
const _hoisted_3 = { class: "store-body store-body-mh" }
const _hoisted_4 = { class: "store-foot" }

import HeadInfo from '../components/store.head.info.vue';
import HeadNav from '../components/store.head.nav.vue';
import DepositList from '@/view/deal/steelProduct/index.vue'
import DealFoot from "../components/store.public.foot.vue";
import { useRoute } from 'vue-router'

export default {
  setup(__props) {

const route = useRoute()
// 获取当前进入商铺公司的id 
const unionList = JSON.parse(localStorage.getItem('unionList'))
const companyIbnfo = unionList.find(v => route.path.includes(v.url));

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HeadInfo),
      _createVNode(HeadNav)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(DepositList, {
        companyId: _unref(companyIbnfo)?.company_id
      }, null, 8, ["companyId"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(DealFoot)
    ])
  ]))
}
}

}